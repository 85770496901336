import React from 'react'
import wgbh897 from "../images/gbh_newlogo.png";
import wcrb995 from "../images/CRB-classical-995-logo.png";

const BannerSection = ({children, category, header, subHeader, image, alt}) => (
  <div id="banner">
    <div className="wrapper">
        <div className={"banner-content " + category}>
          <div className={"banner-btn " + category.toLowerCase()}>
            <div className="icon"></div>
            {category}
          </div>
          <h1 className="banner-header">{header}</h1>

          {category !== 'Radio' && image !== undefined &&
                <div className="image">
                    <img src={image} alt={alt} />
                </div>
            }

          {category !== "Radio" &&
            <h3 className="banner-subheader">{subHeader}</h3>
          }

          {category === 'Radio' &&
            <div className="radio-special">
              <div className="station-wrapper">
                <div className="logo"><img src={wgbh897} alt="GBH 89.7 Boston's Local NPR "/></div>
                <div className="description"><p>GBH 89.7 offers signature programming from NPR, as well as news, analysis and discussion with a local focus.</p></div>
                {image !== undefined &&
                    <div className="image">
                        <img src={image} alt={alt} />
                    </div>
                }
              </div>
              <div className="station-wrapper">
                <div className="logo"><img src={wcrb995} alt="CRB 99.5 Classical Radio Boston "/></div>
                <div className="description"><p>CRB 99.5 is the region's only 24-hour classical music station.</p></div>
              </div>
            </div>
          }
        </div>
    </div>
  </div>
)

export default BannerSection
