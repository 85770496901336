// common---
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicsContactForm from "../components/contactForm/dynamicsContactForm"
import MediaKit from "../components/mediaKit"
// --- end common

import BannerSection from "../components/bannerSection"
import MultiUp from "../components/multiUp"
import MultiUpItem from "../components/multiUpItem"

// Image imports
import adsLeadImage from "../images/Digital-Ads_Dec20.jpg"
import audioLeadImage from "../images/Digital-Audio-Stream_20210128.png"
import enewsLeadImage from "../images/e-newlsetter_Dec20.jpg"
import podcastsLeadImage from "../images/Podcasts_Dec20.jpg"

const adHeader = {
  title: "Display Ads",
  image: adsLeadImage,
}
const audioHeader = {
  title: "Streaming Audio",
  image: audioLeadImage,
}
const enewsHeader = {
  title: "eNewsletters",
  image: enewsLeadImage,
}
const podcastsHeader = {
  title: "Podcasts",
  image: podcastsLeadImage,
}

const DigitalPage = () => (
  <Layout>
    <Seo
      title="Digital"
      isTitleExtended={true}
      description="Expand your reach to a highly-valuable GBH audience with podcast sponsorships or with display ads positioned throughout gbh.org and on GBH eNewsletters."
    />

    <BannerSection
      category="Digital"
      header="Increase Your Impact with a Digital Sponsorship"
      subHeader="Expand your reach through a digital sponsorship on gbh.org and drive traffic to your website."
    />

    <div className="list-section">
      <MultiUp type="two-up">
        <MultiUpItem
          header={adHeader}
          content="<p>Add another platform to your sponsorship package with display ads positioned throughout the GBH site to drive traffic to your web site. Multiple sponsor ad-size units are available.</p>"
        />
        <MultiUpItem
          header={audioHeader}
          content="<p>Sponsor audio pre-roll messages on our popular GBH 89.7 and CRB Classical 99.5 audio live streams reach a local, national and even a global audience and are 100% guaranteed to be heard.</p>"
        />
      </MultiUp>

      <MultiUp type="two-up">
        <MultiUpItem
          header={enewsHeader}
          content="Sponsorship of GBH eNewsletters puts your message in front of a highly targeted opt-in audience who wants updates on GBH television and radio programming and events."
        />
        <MultiUpItem
          header={podcastsHeader}
          content="Podcast sponsorship is an effective way to reach your target audience. Listeners love podcasts, and pay attention to the brands that sponsor them."
        />
      </MultiUp>
    </div>

    <MediaKit />
    <DynamicsContactForm />
  </Layout>
)

export default DigitalPage
