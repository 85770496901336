import React from 'react'

const MultiUp = ({children, type}) => (
  <div className={type + " clearfix"}>
    <ul className="list clearfix">
      {children}
    </ul>
  </div>
)

export default MultiUp