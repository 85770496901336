import React from 'react'

const MultiUpItem = ({header, content, footnote}) => {

    function contentRender(content) {
        if (footnote !== undefined){
            footnote = <sup>*</sup>
        } else {
            footnote = '';
        }

        return {__html: content + footnote }
    }

    return(
        <li>
            <img alt={header.title} src={header.image} className="head_img" />
            <div className="heading">{header.title}</div>

            { content !== undefined &&
                <div className="subhead" dangerouslySetInnerHTML={contentRender(content)}></div>
            }
        </li>
)}

export default MultiUpItem
